<template>
  <v-app id='inspire'>
    <nav-bar />

    <v-main class='grey lighten-3'>
      <v-container>
        <v-row>
          <v-col>
            <v-sheet
              min-height='70vh'
              rounded='lg'
              class='pa-5'
            >
              <v-card
                class='mx-auto my-12'
                max-width='374'
              >
                <template slot='progress'>
                  <v-progress-linear
                    color='deep-purple'
                    height='10'
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-img
                  height='250'
                  src='https://unsplash.it/720'
                ></v-img>

                <v-card-title>Mr.Prayut Chan-ou-cha</v-card-title>

                <v-card-text>
                  <v-row
                    align='center'
                    class='mx-0'
                  >
                    Phone Number: {{ user.phoneNumber }}
                  </v-row>

                </v-card-text>

                <v-divider class='mx-4'></v-divider>

                <v-card-title>Premier Membership</v-card-title>

                <v-card-text>
                  Expire At 20/12/2021
                </v-card-text>

                <v-card-actions>

                </v-card-actions>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar'

export default {
  components: {
    NavBar,
  },
  data: () => ({}),
  computed: {
    user() {
      return this.$store.getters.getUser
    },
  },
}
</script>
